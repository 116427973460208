.banner {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  left: 0;
  right: 0;
  z-index: 1;
  color: #000;
  background: url("../../assets/19366.jpg") center no-repeat;
  background-size: cover;
  height: 100vh;
  animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}
.banner__contents {
  padding-top: 10vh;
}
.banner__Signature {
  font-size: 45px;
  font-family: "Eurostile", serif;
}

.banner__infoText {
  font-size: 24px;
  margin: 25px 60px;
  line-height: 1.5;
  word-spacing: 0.1em;
  font-family: Europa, sans-serif;
  transition: 10s;
}
.banner__img {
  margin-top: 5px;
  height: 280px;
  width: 280px;
  object-fit: contain;
  border-radius: 50%;
  border: none;
}
@media screen and (max-width: 600px) {
  .banner__contents {
    margin-top: 4%;
  }
  .banner__Signature {
    font-size: 32px;
    line-height: 1.3;
  }
  .banner__infoText {
    font-size: 18px;
  }
}
