body {
  background-color: #fdfcfc;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar{
  width:9px;
  border-left: 1px solid #E6ECF8; 
}
body::-webkit-scrollbar-thumb {
  background-color: #141c3a;
}
@font-face {
  font-family: "Eurostile";
  src: local("Eurostile"), url('./assets/fonts/EurostileBold.ttf') format("truetype");
}
@font-face {
  font-family: "Eurostile-Normal";
  src: local("Eurostile-Normal"), url('./assets/fonts/EuroStyle_Normal.ttf') format("truetype");
}


