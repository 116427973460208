.about{
    background: #3E2093;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:20px 35px;
    min-height:650px;
    z-index: 0;
}
.about__content>h2{
    font-family: "Eurostile",sans-serif;
    font-size: 2.3rem;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1px;
}
.about__content{
    max-width: 800px;
}
.about__contentIntro>p{
    padding-top: 20px;
    line-height:2; 
    font-size: 1.2rem;
    text-align: center;   
}
.buymeContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.logo__buymeacoffee,.logo__buymeamomo{
    width: 180px;
    height:50px;
    margin: 15px;
}
.logo__buymeamomo{
    border-radius: 8px;
}
@media only screen and (max-width:600px){
    .buymeContent{
        flex-direction: column;
    }
    .about{
    padding-bottom: 180px;
    }
}
