.footer{
    min-height:45px;
    background-color: #3E2093;
    opacity: 1;
    color:white;
    padding: 5px;
}
.footer__content>h4{
    font-family: "Eurostile",serif;
    text-transform: uppercase;   
    font-size: 30px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 0;
    color:fff;
    text-shadow: 2px 2px #000;
}
.footer__content>p{
    text-align: center;
    font-size: 17px;
    line-height: 1.5;
    font-weight: 600;
    margin-top: 20px;
}