.skills{
    background-color:#fff;
    margin: 20px;
}
.skills__content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.skills__contentInner{
    background-color: #fff;
    z-index: 1;
    display: flex;
    margin: -100px 60px 60px 60px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    min-height: 600px;
}
.skills__contentInner>div{
    margin: 40px;
    min-width: 300px;
    text-align: center;
}
.innerdiv__logo{
    background-color: #3E2093;
    color:white;
    border-radius: 50%;
    padding: 20px;
    margin-bottom: 30px;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.innerdiv__title{
    font-weight:900;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #141C3A;
    font-family: "Eurostile",serif;
}
.innerdiv__para{
    color: #141C3A;
    line-height: 1.5;
    min-height:80px;
    margin-top: 30px;
}
.innerdiv__paraLang{
    color:#3E2093;
    padding-top: 40px;
    font-weight: 600;
}
.innerdiv__paralangLang{
    line-height: 1.5;
    color: #141C3A;
    letter-spacing: 1px;
}
.innerdiv__tools{
    margin-top: 40px;
    margin-bottom: 30px;
}
@media only screen and (max-width:600px){

    .skills__contentInner{
        flex-direction: column;
        margin:-150px 20px 20px 20px;
    }       
    .skills__contentInner>div{
            margin: 30px;  
            min-width: 0px;  
    }
}