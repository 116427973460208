
.contact__contents{
    margin-top: 20px;
    position: relative;
    padding: 20px;
    background-color: #f9f9fb;
}
.contact__contentsTop{
    text-align: center;
}
.contact__contentsTop>h1{
    font-size: 3em;
    margin: 13px;
    color: #000;
    text-transform:uppercase;
    letter-spacing: 1.5px;
    font-family: "Eurostile",serif;
}
.contact__contentsTop>p{
    font-size: 1.3em;
    font-family: "Eurostile-Normal",serif;
    letter-spacing: 1.4px;
}
.contact__contentsBottom{
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:8px 0px;
    border-radius: 15px;
}
.contact__contentsBottomLeftOuter{
    background-color: #FA949D;
    color: #E7E3F1;
    border-radius: 20px;
    height: 390px;
    margin-left:auto;
    margin-right: auto;
}
.contact__contentsBottomLeft{
    background-color: #3e2093;
    height: 350px;
    padding: 20px 30px;
    border-radius: 15px;
    border-bottom-right-radius: 200px;
}
.contact__contentsBottomLeft>h3{
    font-size: 1.3rem;
    letter-spacing: 1px;
    line-height: 1.5;
}
.contact__contentsBottomLeft>p{
    line-height: 1.5;
}
.contact__contentsBottomLeftContact{
    padding: 15px 25px;
}
.contact__contentsBottomLeftContact>div{
    margin-bottom: 30px;
    display: flex;
}
.phone>a,.email>a,.location{
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.7px;
    text-decoration: none;
    color: white;
}

.icon{
    margin-right: 30px;
    font-size: 25px;
    color: #FB959D;
}
.contact__contentsBottomRight{
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    max-width: 700px;
    border-radius: 15px;
}
.contact__contentsBottomRight>form>input{
    height:30px;
    font-size: 15px;
    width: 40%;
    margin:0px 20px 25px 10px;
    padding: 10px;
    border: none;
    outline: none !important;
    border-bottom: 1px solid #DEDEE7;
}
input::placeholder,textarea::placeholder{
    font-size: 15px;
    font-family:Arial, Helvetica, sans-serif;
}
input,textarea{
    font-family:Arial, Helvetica, sans-serif;
}
.contact__contentsBottomRight>form>textarea{
    width: 90%;
    font-size: 15px;
    padding: 10px;
    margin-top: 7px;
    margin-left: 5px;
    line-height: 1.5;
    border: none;
    outline: none !important;
    border-bottom: 1px solid #DEDEE7;
}
input:focus,textarea:focus{
    border-bottom: 2px solid #5827E1 !important;
    border-radius: 2px;
}
.submit__button{
    background-color: #3E2093;
    margin-top: 25px;
    padding: 15px;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    opacity: 0.9;
}
.submit__button:hover{
    cursor: pointer;
    background-color:#483187;
    box-shadow: 0px 5px 10px #483187;
    opacity: 1;
}
@media screen and (max-width: 600px) {
    .contact__contentsBottom{
        flex-direction: column;
    }
    .contact__contentsBottomRight{
        padding-left: 4px;
    }
    .contact__contentsBottomRight>form>input{
        width: 100%;
    }
    .contact__contentsBottomRight>form>textarea{
        width: 100%;
        margin-left: 7px;
    }
    }
    .close__button{
        color:#3939D1;
        font-size: 30px;
        right:100px;
        margin-top: 10px;
        position:fixed;
    }
    .close__button:hover{
        cursor: pointer;
    }